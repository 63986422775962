export default [
	{
	 	name: "Gunn Model UN Conference",
	 	date: "October 21, 2023",
	 	location: "Henry M. Gunn High School",
	 	acronym: "gmunc",
	 	text: `Gunn Model United Nations Conference is our first conference of the year, and the one where we always take the most novices too.`,
		//registration: "https://docs.google.com/forms/d/1koPXq06Gys8j8nLwvW6ilxE6U-avNnudD7ou9rMTaIc/",
		//registrationText: "Application deadline 9/21",
	},
	// {
	//  	name: "Stanford Model UN Conference",
	//  	date: "November 10, 2023 - November 12, 2023",
	//  	location: "Stanford University",
	//  	acronym: "smunc",
	//  	text: `The Stanford Model United Nations Conference is one of the most competitive conferences of the year, but also one with most to offer. With three days packed with five committee sessions, it may leave you a bit exhausted, but never with any regrets about attending!`,
	//  	//registration: "https://docs.google.com/forms/d/1VdAGqj2s-GnTT_BoyWMhHmDeE3Vq1IAusn1C5Gvdoec",
	//  	//registrationText: "Sign up now! Application closes 10/19",
	// },
	/*{
		name: "Bruin Model UN Conference",
		date: "November 6, 2021 - November 7, 2021",
		location: "Virtual",
		acronym: "BruinMUN",
		text:
			"BruinMUN is our first conference of the year. It’s a great chance for newer delegates to try out the skills and procedures you’ve learned and for experienced delegates to get a refresher, all from the comfort of your own home. All in all, it’s an excellent way to jump into MUN for the year!",
		// registration: "/conferences/bruinmun/register",
		// registrationText: "Application Now Open",
	},*/
	/*{
		name: "San Fransisco Model United Nations Conference",
		date: "December 9, 2023 - December 10, 2023",
		location: "Lowell High School",
		acronym: "sfmun",
		text:
			"San Francisco Model United Nations conference is held by Lowell High School. One of the most beginner-friendly conferences we attend, SFMUN is a great way to get started in Model UN, while also providing experienced delegates with plenty of chances to hone their skills. It’s a much less intense and more beginner-friendly conference, and it’s highly recommended for all delegates to attend.",
		// registration: "/conferences/sfmun21/register",
	},*/
	{
		name: "North California Model United Nations Conference",
		date: "December 9, 2023 - December 10, 2023",
		location: "Foothill High School",
		acronym: "norcalmun",
		text:
			"Northern California Model United Nations Conference is held at Foothill Highschool. The conference has numerous committees for delegates of all skill levels and is recommended for both old and new delegates.",
		//registration: "https://forms.gle/NZB5Kzm8MD9gbsfi8",
		//registrationText: "Sign up by 11/9!",
	},
	{
		name: "Gaucho Model United Nations Conference",
		date: "February 2, 2024 - February 3, 2024",
		location: "University of California, Santa Barbara",
		acronym: "gauchomun",
		text:
			"Gaucho Model United Nations Conference is one of our travel conferences in SoCal. It’s a great way to use many of the skills you've aquired throughout the year and it’s highly recommended for all delegates to attend.",
	},
	{
		name: "Golden State Model United Nations Conference",
		date: "February 24, 2024",
		location: "Granite Bay High School",
		acronym: "gsmunc",
		text:
			"Golden State Model United Nations Conference is held at Granite Bay High School. The conference has numerous committees for delegates of all skill levels and is recommended for both old and new delegates.",
		// registration: "https://bit.ly/naimun2022app",
		// registrationText: "Application Open!",
	},
	{
		name: "National High School Model United Nations Conference",
		date: "March 8, 2024 - March 11, 2024",
		location: "New York Hilton Midtown and United Nations Headquaters",
		acronym: "nhsmun",
		text:
			"The National High School Model United Nations Conference is one of our travel conferences. NHSMUN is the world's largest, most diverse, and most prestigious MUN conference for high-school students, with students from over 130 countries hosted. Don’t let all that scare you, though: it’s often best remembered by delegates as a 5-day trip to New York City, NY, full of chances to meet new people and make new friends.",
		// registration: "https://forms.gle/UCbJ9vVxqaYU8caT7",
		// registrationText: "Apply Now! Applications due 12/1",
	},

	{
		name: "South Bay Model United Nations",
		date: "TBD",
		location: "Monta Vista High School",
		acronym: "sbmun",
		text:
			"Co-hosted by Homestead and Monta Vista HS",
		// registration: "/conferences/sbmun/register",
	},
	{
		name: "Davis Model United Nations Conference",
		date: "May 18, 2024 - May 19, 2024",
		location: "University of California, Davis",
		acronym: "dmunc",
		text:
			"Our final conference of the year, Davis Model United Nations Conference, is an excellent way for you to put the skills you’ve accumulated during the year to the test. DMUNC has a variety of both novices and experienced members, and we’re sure that you will do great after practicing for so long! DMUNC is our last overnight conference, including a likely 2-night stay at a hotel near the Davis campus.",
	},
];
